import styled, {css} from 'styled-components';
const ButtonStyle = styled.button` 

    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center; 
    // font-family: inherit; 
    font-family: 'Poppins',sans-serif;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600; 
    border: 0; 
    min-width: 170px;
    padding: 6px;
    // border-radius: 3px;
    -webkit-transition: 450ms all;
    transition: 450ms all;
    position: relative;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.primaryColor};
    // box-shadow: 5px 5px 0px ${props => props.theme.yellow};
    border: 2px solid ${props => props.theme.white};

    z-index: ${props => props.zIndex ? props.zIndex :0};

    @media only screen and (max-width: 480px) {
        min-width: 120px;
        font-size: 14px;
        padding: 2px;
        box-shadow:none;
    }


    &:hover,
    &:focus {
        outline: none;
        background: ${props => props.theme.white};
        color: ${props => props.theme.primaryColor};
        border: 2px solid ${props => props.theme.white};
        box-shadow: 5px 5px 0px ${props => props.theme.primaryColor};
    }
    .icon-left{
        margin-right: 8px;
    }
    .icon-right{
        margin-left: 8px;
    }

    ${props => props.primary && css`
        color: ${props.theme.primaryLightColor};
        background: ${props.theme.primaryColor};

        &:hover{
            color: ${props.theme.primaryColor};
            background: ${props.theme.primaryLightColor}; 
        }
    `}
    ${props => props.secondary && css`
        color: ${props.theme.secondaryColor};
        background: ${props.theme.secondaryLightColor};

        &:hover{
            color: ${props.theme.secondaryLightColor};
            background: ${props.theme.secondaryColor}; 
        }
    `}
`
ButtonStyle.displayName = 'ButtonStyle';

export default ButtonStyle;