import React from 'react';
import styled, {css} from 'styled-components'


const getColor = (props) => {
    if (props.inherit){
        return 'inherit'
    }else if(props.textColor){
        return props.theme.textColor
    } else if(props.headingColor){
        return props.theme.headingColor
    } else if(props.blue){
        return props.theme.blue
    } else if(props.yellow){
        return props.theme.yellow
    }else if(props.white){
        return props.theme.white
    }else if(props.black){
        return props.theme.black
    } else {
        return 'red'
    }
}

const TitleWrap = styled.div`
margin-left: auto;
margin-right: auto;
width: 40%;
text-align: center;

h1,h2,h3,h4{

    font-family: 'Poppins', sans-serif;
    color: ${props => getColor(props)};
}


${props => (props.sectionPaddingBottom && `
    padding-top: 40px;
    padding-bottom: 40px;
`)}


${props => (props.rightAlign && css (
    {
    "text-align": 'right'
    }
))}
${props => (props.leftAlign && css (
    {
    "text-align": 'left'
    }
))}
${props => (props.UniWidth && css (
    {
    "width": props.UniWidth
    }
))}

h4{
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 16px 0;
}
h2{
    font-size: 40px;
    font-weight: 300;
    line-height: 50px;
    margin: 0 0 25px 0;
    span{
        font-weight: 600;
    }
}
@media only screen and (max-width: 912px) {
    width: 100%;
    h2{
        font-size: 24px;
        line-height: 35px;
    }
    .section-title h2{
        margin-bottom: 20px;
    }
}

.line{
    width: 30%;
    height: 20px;
    border-bottom: 2px solid red;
    margin:auto
    border-color:  ${props => getColor(props)};
    margin-bottom: 20px
    margin-top: 20px
}
`
const SectionTitle = ({ children, withLine, ...props }) => {

  return (
    <TitleWrap {...props}>
        {children}
        {withLine && (
            <div className='line'></div>
        )}
    </TitleWrap>
  );
};

export default SectionTitle;
